import ApiService from "@/core/services/api.service";

export const GET_TEMPLATES = "template/get-template";
export const GET_CURRENT_TEMPLATE = "template/get-current-template";
export const SAVE_TEMPLATE = "business/settings/save-template";
export const UPLOAD_TEMPLATE_IMAGES = "business/settings/upload-template-image";


// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ADMIN_INFO = "updateAdminPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  user_personal_info: {},
  user_account_info: {},
  rows: [10, 25, 50, 100],
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    if (
      sessionStorage.getItem("personalInfo") &&
      (sessionStorage.getItem("personalInfo") != null ||
        sessionStorage.getItem("personalInfo") != "")
    ) {
      context.commit(
        SET_PERSONAL_INFO,
        JSON.parse(sessionStorage.getItem("personalInfo"))
      );
    } else {
      return new Promise(resolve => {
        ApiService.get("business/profile", "info")
          .then(({ data }) => {
            sessionStorage.setItem("personalInfo", JSON.stringify(data.data));
            context.commit(SET_PERSONAL_INFO, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    }
  },

  [UPDATE_ADMIN_INFO](context, payload) {
    console.log(payload);
    context.commit(
      SET_PERSONAL_INFO,
      payload
    );
  },

  [GET_TEMPLATES](context, payload) {
    return new Promise(resolve => {
      ApiService.post(GET_TEMPLATES, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_CURRENT_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(GET_CURRENT_TEMPLATE, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [SAVE_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(SAVE_TEMPLATE, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [UPLOAD_TEMPLATE_IMAGES](context, payload) {
    return new Promise(resolve => {
      ApiService.post(UPLOAD_TEMPLATE_IMAGES, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
