import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import * as VueGoogleMaps from "vue2-google-maps";
//import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH, SET_AUTH } from "@/core/services/store/auth.module";
import { UPDATE_PERSONAL_INFO, UPDATE_ADMIN_INFO } from "@/core/services/store/profile.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'typeface-poppins';

// API service init
ApiService.init();

// document.title = "Get Me Food - Business Portal";

router.beforeEach((to, from, next) => {
  let userAuth = sessionStorage.getItem('USER_STATE');
  let authParsed = JSON.parse(userAuth);
  // if (store.state.auth.normal) {
  //   document.title = "Get Me Food - Business Portal";
  // } else {
  //   document.title = "Get Me Food - Admin Portal";
  // }

  // if(store.state.auth.isAuthenticated){
  //   // Ensure we checked auth before each page load.
  //   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  // }else {
  //   console.log("not logged in");
  //   next();
  // }

  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  if (authParsed) {
    store.commit(SET_AUTH, authParsed);
  }

  next();
  console.log("main", authParsed);

  if (authParsed.normal) {
    // store.commit(SET_AUTH, authParsed.user);
    store.dispatch(UPDATE_PERSONAL_INFO);
  } else {
    // store.commit(SET_AUTH, authParsed.user);
    store.dispatch(UPDATE_ADMIN_INFO, authParsed.user);
  }
  store.dispatch(SET_BREADCRUMB, []);
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueGoogleMaps);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
